import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  imageBlock: {
    display: 'block',
    height: 'calc(100vh - 160px)',
    maxHeight: '345px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '480px',
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: '2/3',
      maxHeight: '702px',
      minHeight: '472px',
    },
    width: '100%',
    objectFit: 'cover',
  },
}));

type Props = {
  url: string;
  altText?: string;
};

const ImageBlock: React.FC<Props> = ({ url, altText }) => {
  const classes = useStyles();

  const cloudinaryURL = getCloudinaryImgUrl(url, {
    w: 480,
    dpr: 2,
  });
  const cloudinarySrcSet = getCloudinarySrcSet(url, [480, 800, 1200, 1600]);

  return (
    <img
      className={classes.imageBlock}
      srcSet={cloudinarySrcSet}
      src={cloudinaryURL}
      alt={altText}
    />
  );
};
export default ImageBlock;
