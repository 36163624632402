import { Check } from '@finn/design-system/icons/check';
import { GroupFilled } from '@finn/design-system/icons/group-filled';
import { StarFilled } from '@finn/design-system/icons/star-filled';
import React from 'react';

import { HeroStat } from './metaData';

type Props = {
  stats: HeroStat[];
};

const getIcon = (stat: HeroStat) => {
  if (stat.icon.key === 'users') {
    return <GroupFilled />;
  } else if (stat.icon.key === 'star') {
    return <StarFilled />;
  } else {
    return <Check />;
  }
};

const Stats: React.FC<Props> = ({ stats }) => {
  if (!stats || stats.length === 0) return null;

  return (
    <ul className="mb-6 flex p-0">
      <div className="bg-snow p-4">
        {stats.map((stat) => (
          <li key={stat.stat_text} className="flex items-center">
            <div className="mr-2">{getIcon(stat)}</div>
            <p className="body-16-semibold py-2">{stat.stat_text}</p>
          </li>
        ))}
      </div>
    </ul>
  );
};
export default Stats;
