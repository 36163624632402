import { CTA } from '@finn/ui-components';
import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import React from 'react';

import RawHtml from '~/components/RawHtml';

import { Hero2CosmicMetadata } from './metaData';

type Props = {
  data: Hero2CosmicMetadata;
};

export const HeroB: React.FC<Props> = ({ data }) => {
  if (!data) return null;
  const { label, title, description } = data;

  const cloudinaryURL = getCloudinaryImgUrl(data.hero_image.url, {
    w: 480,
    dpr: 2,
  });
  const cloudinarySrcSet = getCloudinarySrcSet(
    data.hero_image.url,
    [480, 800, 1200, 1600]
  );

  return (
    <div className="container" data-testid="hero2">
      <div className="-mb-10 grid gap-4 sm:grid-cols-[400px_1fr] md:-mb-24 md:grid-cols-[620px_1fr]">
        <div className="mt-10 w-full md:mt-16">
          <h1
            className="mobile-t1-semibold md:web-t1-semibold mb-6 mt-2 text-center sm:text-left"
            data-cy="hero-title"
          >
            {label && (
              <div className="body-16-semibold mb-2 block">{label}</div>
            )}
            {title}
          </h1>
          {description && (
            <RawHtml
              className="mb-6 text-center sm:text-left"
              html={description}
              useGlobalStyles
              fullWidthMedia
            />
          )}

          {data.primary_cta?.metadata && (
            <CTA
              data={data.primary_cta.metadata}
              parentBG="light"
              classNames="[&>a]:w-full sm:[&>a]:w-auto"
              fullWidth
            />
          )}
        </div>
        <div className="hidden sm:block">
          <img
            className="block w-full object-contain sm:h-80 md:h-[350px]"
            srcSet={cloudinarySrcSet}
            src={cloudinaryURL}
            alt={data.hero_image_alt_text}
          />
        </div>
      </div>
    </div>
  );
};
