import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { useIsMobile } from '@finn/ui-utils';
import { Box } from '@material-ui/core';
import React from 'react';

type Props = {
  primaryCTA: CTAData;
  secondaryCTA: CTAData;
  onSecondaryCTAClick?: () => void;
  fullWidth?: boolean;
};

const CTAs: React.FC<Props> = ({
  primaryCTA,
  secondaryCTA,
  onSecondaryCTAClick = () => {},
  fullWidth = false,
}) => {
  const isMobile = useIsMobile();
  if (!primaryCTA?.metadata && !secondaryCTA?.metadata) return null;

  return (
    <Box
      display="flex"
      style={{ gap: '16px' }}
      mb={isMobile ? 3 : 4}
      flexWrap="wrap"
    >
      {primaryCTA?.metadata && (
        <CTA
          data={primaryCTA.metadata}
          parentBG="light"
          fullWidth={fullWidth}
        />
      )}
      {secondaryCTA?.metadata && (
        <CTA
          data={secondaryCTA.metadata}
          parentBG="light"
          onCTAClick={onSecondaryCTAClick}
        />
      )}
    </Box>
  );
};
export default CTAs;
