// we use unsafe colors here because we need
// to style globally internals of custom html
// which is trickier with tailwind
import unsafeColors from '@finn/design-system/unsafe_colors';
import { typography as UnsafeFonts } from '@finn/design-system/unsafe_fonts';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React from 'react';

const unsafeFonts = UnsafeFonts();

const useStyles = makeStyles((theme: Theme) => ({
  globalStyles: {
    ...unsafeFonts['.body-16-light'],
    '& a': {
      ...unsafeFonts['.link-16'],
      '&:before, &:after': {
        display: 'inline',
      },
    },
    '& p': {
      ...unsafeFonts['.body-16-light'],
      '&:before, &:after': {
        display: 'inline',
      },
      textAlign: 'inherit !important',
      margin: 0,
    },
    '& h1,& .h1': {
      ...unsafeFonts['.web-t1-semibold'],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& h2,& .h2': {
      ...unsafeFonts['.web-t2-semibold'],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& h3,& .h3': {
      ...unsafeFonts['.web-t3-semibold'],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& h4,& .h4': {
      ...unsafeFonts['.web-t4-semibold'],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& h5,& .h5': {
      ...unsafeFonts['.global-t5-semibold'],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& h6,& .h6': {
      ...unsafeFonts['.global-t6-semibold'],
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& .sea-section': {
      '& .benefits-wrapper': {
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
        },
        '& span': {
          justifyContent: 'center',
        },
      },
    },
    '& table': {
      width: '100%',
      display: 'block',
      marginTop: theme.spacing(2),
      backgroundColor: unsafeColors.white,
      borderCollapse: 'collapse',
      border: `1px solid ${unsafeColors.snow}`,
      [theme.breakpoints.up('sm')]: {
        display: 'table',
      },
      '& tr': {
        border: `1px solid ${unsafeColors.snow}`,
        height: theme.spacing(10),
        width: '100%',
      },
      '& tr:first-child td': {
        height: theme.spacing(10),
        '& *': {
          textAlign: 'center !important',
        },
      },
      '& td': {
        minWidth: 200,
        '& li': {
          textAlign: 'left !important',
        },
      },
      '& tr td:first-child': {
        minWidth: 200,
        '& *': {
          textAlign: 'center !important',
        },
      },
      '& tbody': {
        display: 'block',
        'overflow-x': 'auto',
        [theme.breakpoints.up('sm')]: {
          display: 'table-row-group',
        },
      },
    },
  },
  fullWidthStyles: {
    '& img': {
      width: '100% !important',
      minHeight: 270,
    },
    [theme.breakpoints.up(374)]: {
      '& img': {
        minHeight: 330,
      },
    },
    [theme.breakpoints.up(599)]: {
      '& img': {
        minHeight: 69,
      },
    },
    [theme.breakpoints.up(959)]: {
      '& img': {
        minHeight: 112,
      },
    },
    [theme.breakpoints.up(1119)]: {
      '& img': {
        minHeight: 120,
      },
    },
    [theme.breakpoints.up(1259)]: {
      '& img': {
        minHeight: 137,
      },
    },
    [theme.breakpoints.up(1439)]: {
      '& img': {
        minHeight: 152,
      },
    },
    '& .fr-video, video, iframe': {
      width: '100% !important',
    },
  },
}));

interface IProps {
  html: string;
  className?: string;
  useGlobalStyles?: boolean;
  fullWidthMedia?: boolean;
}

const RawHtml: React.FunctionComponent<IProps> = ({
  html,
  className,
  useGlobalStyles = false,
  fullWidthMedia = false,
}) => {
  const classes = useStyles();

  return (
    <div
      className={cn(
        className,
        { [classes.globalStyles]: useGlobalStyles },
        { [classes.fullWidthStyles]: fullWidthMedia }
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default RawHtml;
