import { CTA } from '@finn/ui-components';
import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import React from 'react';

import RawHtml from '~/components/RawHtml';

import { Hero2CosmicMetadata } from './metaData';

type Props = {
  data: Hero2CosmicMetadata;
};

export const HeroC: React.FC<Props> = ({ data }) => {
  if (!data) return null;
  const { label, title, description } = data;

  const cloudinaryURL = getCloudinaryImgUrl(data.hero_image.url, {
    w: 480,
    dpr: 2,
  });
  const cloudinarySrcSet = getCloudinarySrcSet(
    data.hero_image.url,
    [480, 800, 1200, 1600]
  );

  return (
    <div data-testid="hero2" className="mx-auto -mb-10 max-w-screen-lg">
      <div className="w-100 container flex flex-col">
        <div className="max-w-2xl self-center">
          <h1
            className="mobile-t1-semibold md:web-t1-semibold mb-6 mt-10 text-center"
            data-cy="hero-title"
          >
            {label && (
              <div className="body-16-semibold mb-2 block">{label}</div>
            )}
            {title}
          </h1>
          {description && (
            <RawHtml
              className="mb-6 text-center"
              html={description}
              useGlobalStyles
              fullWidthMedia
            />
          )}

          <div className="mb-10 flex flex-col">
            {data.primary_cta?.metadata && (
              <CTA
                data={data.primary_cta.metadata}
                parentBG="light"
                classNames="self-center w-full md:w-auto [&>a]:w-full md:[&>a]:w-auto"
              />
            )}
          </div>
        </div>
      </div>
      <img
        className="block h-56 w-full object-cover object-top sm:h-80 md:h-[576px]"
        srcSet={cloudinarySrcSet}
        src={cloudinaryURL}
        alt={data.hero_image_alt_text}
      />
    </div>
  );
};
